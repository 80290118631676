<script setup>
import { ref, computed, watch } from 'vue'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import DOMPurify from 'dompurify'
import Spinner from '~/components/Spinner.vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'


const store = useStore()
const { t } = useI18n() // Obtiene la función de traducción i18n

// Estados de carga y error
const isLoading = ref(false)
const errorMsg = ref(null)

// Filtro por fecha (valor)
const filterDate = ref('')

// Array que contendrá los correos programados
const scheduledEmails = ref([])

// Variables para la paginación
const totalPages = ref(1)
const currentPage = ref(1)
const visiblePages = computed(() => {
    const pages = []
    const start = Math.max(1, currentPage.value - 2)
    const end = Math.min(totalPages.value, currentPage.value + 2)
    for (let i = start; i <= end; i++) {
        pages.push(i)
    }
    return pages
})

// Función para obtener los correos programados desde el store
const fetchScheduledEmails = async (filter = null, value = null, page = 1) => {
    isLoading.value = true
    errorMsg.value = null
    try {
        const payload = { filter, value, page, pageSize: 20 }
        const data = await store.dispatch('FETCH_SCHEDULED_EMAILS', payload)
        scheduledEmails.value = data.objects || []
        totalPages.value = data.maxPages  || 1
        currentPage.value = data.page || page
        if (scheduledEmails.value.length === 0) {
            errorMsg.value = t('noEmailsFound')
        }
    } catch (err) {
        errorMsg.value = t('errorLoadingEmails')
        console.error(err)
    } finally {
        isLoading.value = false
    }
}

// Función para cambiar de página
const changePage = async (newPage) => {
    if (newPage < 1 || newPage > totalPages.value) return
    await fetchScheduledEmails("date", filterDate.value, newPage)
}

// Función para eliminar un correo programado mediante el store
const deleteScheduledEmailFromStore = async (id) => {
    try {
        isLoading.value = true
        await store.dispatch('DELETE_SCHEDULED_EMAIL', { id })
        await fetchScheduledEmails("date", filterDate.value, currentPage.value)

        Swal.fire({
            icon: 'success',
            title: t('emailDeletedTitle'),
            text: t('emailDeletedMessage'),
            confirmButtonText: t('ok')
        })
    } catch (err) {
        errorMsg.value = t('errorDeletingEmail')
        console.error(err)

        Swal.fire({
            icon: 'error',
            title: t('error'),
            text: t('errorDeletingEmail'),
            confirmButtonText: t('ok')
        })
    } finally {
        isLoading.value = false
    }
}

// Función que se invoca al confirmar la eliminación
const deleteScheduledEmail = async (id) => {
    const result = await Swal.fire({
        title: t('confirmDeletionTitle'),
        text: t('confirmDeletionMessage'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('action_accept'),
        cancelButtonText: t('action_cancel'),
    })

    if (result.isConfirmed) {
        await deleteScheduledEmailFromStore(id)
    }
}

// Si el usuario borra el valor del input, se limpia el filtro y se vuelve a consultar sin fecha
watch(filterDate, async (newVal) => {
    if (!newVal) {
        await fetchScheduledEmails(null, null, 1)
    }
})

defineExpose({
    fetchScheduledEmails
})
</script>


<template>
    <div class="ScheduledMailPage">
        <div class="header">
            <h1>Correos Programados</h1>
            <Spinner v-if="isLoading" dark />
        </div>
        <div class="filter-bar">
            <label for="filterDate">{{ $t("filterBy") }}:</label>
            <!-- En la vista, filterDate se unwrapped; al llamar la función, se pasa como valor -->
            <input id="filterDate" type="date" v-model="filterDate" />
            <button @click="fetchScheduledEmails('date', filterDate, 1)">{{ $t("search") }}</button>
        </div>
        <div class="mail-list">
            <p v-if="errorMsg">{{ $t(errorMsg) }}</p>
            <ul class="list" v-else>
                <li v-for="(email, index) in scheduledEmails" :key="email.identifier" class="list-item">
                    <!-- Badge de estado en la esquina superior derecha -->
                    <div class="status-badge" :class="email.status.toLowerCase()">
                        {{ this.$t(email.status) }}
                    </div>
                    <div class="email-details">
                        <p><strong>{{ $t('subject') }}:</strong> {{ email.subject }}</p>
                        <p><strong>{{ $t("toWho") }}:</strong> {{ email.from }}</p>
                        <p>
                            <strong>{{ $t("content") }}:</strong>
                            <span v-html="DOMPurify.sanitize(email.contentHtml, { ALLOWED_TAGS: [] })"></span>
                        </p>
                        <p class="email-details--date">
                            <strong>{{ $t('date') }}:</strong>
                            {{ moment(email.scheduledDate).format('DD/MM/YYYY HH:mm:ss') }}
                        </p>
                        <p>
                            <strong>{{ email.communityRecipients.length }} testers</strong>
                        </p>
                    </div>
                    <!-- Se muestra el botón de eliminar solo si el correo no está en estado SENT -->
                    <div v-if="email.status !== 'SENT'" class="actions">
                        <button @click="deleteScheduledEmail(email.identifier)">{{ $t("action_delete") }}</button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="pagination" v-if="totalPages > 1">
            <button @click="changePage(1)" :disabled="currentPage === 1" :title="$t('first')">
                <i class="fas fa-angle-double-left"></i>
            </button>
            <button @click="changePage(currentPage - 1)" :disabled="currentPage <= 1" :title="$t('previous')">
                <i class="fas fa-angle-left"></i>
            </button>
            <button v-for="pageNumber in visiblePages" :key="pageNumber" @click="changePage(pageNumber)"
                :class="{ active: pageNumber === currentPage }" :title="$t('page', { pageNumber })">
                {{ pageNumber }}
            </button>
            <button @click="changePage(currentPage + 1)" :disabled="currentPage >= totalPages" :title="$t('next')">
                <i class="fas fa-angle-right"></i>
            </button>
            <button @click="changePage(totalPages)" :disabled="currentPage === totalPages" :title="$t('last')">
                <i class="fas fa-angle-double-right"></i>
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.ScheduledMailPage {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 70vh;
    padding: 20px;
    background: #F5F5F5;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;

    input[type="date"] {
        padding: 0.3rem;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    button {
        background: var(--primary-color);
        color: #fff;
        border: none;
        padding: 0.4rem 0.6rem;
        border-radius: 4px;
        cursor: pointer;
        transition: background 0.2s;
    }
}

.mail-list {
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-item {
    position: relative;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

/* Badge de estado */
.status-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
}

.status-badge.new {
    background-color: #e0f7fa;
    border: 1px solid #00acc1;
    color: #007c91;
}

.status-badge.processing {
    background-color: #fff3e0;
    border: 1px solid #ff9800;
    color: #e65100;
}

.status-badge.sent {
    background-color: #e8f5e9;
    border: 1px solid #4caf50;
    color: #1b5e20;
}

.status-badge.error {
    background-color: #ffebee;
    border: 1px solid #f44336;
    color: #b71c1c;
}

.email-details {
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 1rem;


    p {
        margin: 0.3rem 0;
    }

    &--date {
        margin-top: 1rem!important;
        color: #6b7280;
        font-weight: bold;
    }
}

.actions {
    text-align: right;
}

.actions button {
    background: #ff4d4f;
    color: #fff;
    border: none;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s;
}

.actions button:hover {
    background: #d9363e;
}

.pagination {
    padding: 20px 0.5rem 0.5rem 0.5rem;
    text-align: center;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    border-top: 1px solid #ccc;
}

.pagination button {
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #f0f0f0;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.pagination button:hover:not(:disabled) {
    background: #ddd;
    font-weight: bold;
}

.pagination button.active {
    background: #bbb;
    border-color: #999;
    font-weight: bold;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
