<template lang="html">
  <GradientBackLayout class="EmailCustomPageV2" :title="$filters.translate('notifications').toUpperCase()"
    :shadowText="$t('notification_email_header')">
    <div class="EmailCustomPageV2--Container">
      <div class="EmailCustomPageV2--PageTabs">
        <Tabs class="EmailCustomPageV2--Tabs" :tabs="pageTabs" @change="handleTabsChange($event)"
          :activeOnLoad="pageTabs[0].id" />
      </div>
      <BoxShadow class="EmailCustomPageV2--Box" v-show="pageTabsActive === 0">
        
          <div class="EmailCustomPageV2--Switcher">
            <a-tooltip class="info-icon" @click="handleCopy" placement="left" v-if="switchTab == 0">
              <template #title>{{ tooltipTitle }}</template>
              <span>
                <i class="fa fa-info-circle"></i>
              </span>
            </a-tooltip>
            <label>{{ notificationSourceTab[0].name }}</label>
            <SwitchInput class="SwitchInput--Small" v-model="switchTab" />
            <label>{{ notificationSourceTab[1].name }}</label>
          </div>
          <div v-if="selectedTab === notificationSourceTab[0].id" class="EmailCustomPageV2--Mail">
            <div class="EmailCustomPageV2--MailInfo">
              <h1>{{$t('custom_mail_send')}}</h1>
              <p>{{$t('custom_email_info')}}</p>
              <CommunityButton :color="theme.buttonColor" v-if="!isEditing" @click="isEditing = true">
                {{$t('custom_mail_write')}}</CommunityButton>
              <CommunityButton :color="theme.buttonColor" v-if="isEditing" @click="isEditing = false">
                {{$t('action_finished')}}</CommunityButton>
            </div>
            <div class="EmailCustomPageV2--MailEditionContainer">
              <div class="EmailCustomPageV2--MailEdition">
                <CommunityTextInput :disabled="!isEditing" ref="subject"
                  :placeholder="$t('custom_mail_subject_placeholder')" v-model="email.subject" :required="true" />
                <div class="EmailCustomPageV2--MailPhoto">
                  <img :src="theme.logoUrl" />
                </div>
                <div class="EmailCustomPageV2--MailContent" v-html="email.content" v-if="!isEditing">
                </div>
                <div v-if="isEditing" @paste="controlPasteEvent">
                  <vue-editor class="EmailCustomPageV2--MailContentEditor" ref="editor" v-model="email.content"
                    :editorOptions="editorOptions">
                  </vue-editor>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="EmailCustomPageV2--Notification">
            <div class="EmailCustomPageV2--NotificationInfo">
              <h1>{{$t('custom_notification_send')}}</h1>
              <p>{{$t('custom_notification_info')}}</p>
              <CommunityButton :color="theme.buttonColor" v-if="!isNotificationEditing"
                @click="isNotificationEditing = true">{{$t('custom_mail_write')}}</CommunityButton>
              <CommunityButton :color="theme.buttonColor" v-if="isNotificationEditing"
                @click="isNotificationEditing = false">{{$t('action_finished')}}</CommunityButton>
            </div>
            <div class="EmailCustomPageV2--NotificationEdition">
              <CommunityTextInput :disabled="!isNotificationEditing" ref="notificationSubject"
                :placeholder="$t('custom_notification_subject_placeholder')" v-model="notification.title"
                :required="true" />
              <div class="EmailCustomPageV2--MailPhoto">
                <img :src="theme.logoUrl" />
              </div>
              <div class="EmailCustomPageV2--NotificationContent" v-if="!isNotificationEditing">
                {{ notification.text }}
              </div>
              <div v-if="isNotificationEditing" @paste="controlPasteEvent">
                <textarea class="EmailCustomPageV2--NotificationContentEditor" :autofocus="isNotificationEditing"
                  v-model="notification.text"></textarea>
              </div>
            </div>
          </div>
          <Separator />
          <div class="EmailCustomPageV2--Participants">
            <CommunityUserAssignment ref="userAssingment" class="UserAssignment" :members="members"
              :emailButton="selectedTab === notificationSourceTab[0].id"
              :notificationButton="selectedTab === notificationSourceTab[1].id" :tags="tags"
              :hideGamification="communityInfo.hideGamification" fromEmailCustomPage
              :communityDateTo="communityInfo.dateTo" @send-email="sendEmail" @program-send-email="sendScheduledEmail"
              @send-notification="sendNotification" @filter-all="fetchMembers($event,'filtered')"
              @filter-added="fetchMembers($event,'filteredAdded')" />
          </div>
        </BoxShadow>
        <BoxShadow class="EmailCustomPageV2--Box" v-show="pageTabsActive === 1">
          <div class="EmailCustomPageV2--History">
            <CustomMailHistory ref="CustomMailHistory" />
          </div>
        </BoxShadow>
        <BoxShadow class="EmailCustomPageV2--Box" v-show="pageTabsActive === 2">
          <div class="EmailCustomPageV2--Scheduled">
            <ScheduledMailManagement ref="ScheduledEmailComponent" />
          </div>
        </BoxShadow>
    </div>

  </GradientBackLayout>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
import store from '@/store';
import { COMMUNITY_FETCH_MEMBERS } from '@/store/community/members.module';
import { COMMUNITY_FETCH_TAGS } from '@/store/community/tags.module'
import { COMMUNITY_SEND_EMAIL,COMMUNITY_SCHEDULE_SEND_EMAIL, COMMUNITY_SEND_NOTIFICATION, UPLOAD_IMG_TO_S3 } from '@/store/community/info.module';
import { VueEditor } from "vue3-editor";
import CommunityUser from '@/components/CommunityUser.vue';
import CommunityButton from '@/components/CommunityButton';
import GradientBackLayout from '@/layouts/GradientBackLayout'
import BoxShadow from '@/components/BoxShadow.vue'
import Separator from '@/components/Separator'
import CommunityTextInput from '@/components/CommunityTextInput.vue';
import CommunityUserAssignment from '@/components/CommunityUserAssignment.vue'
import CustomMailHistory from '@/components/CustomMailHistory.vue'
import ScheduledMailManagement from '@/components/ScheduledMailManagement.vue'
import SwitchInput from "~/components/SwitchInput/SwitchInput"
import Tabs from "~/components/Tabs";


export default {
  name: 'EmailCustomPage',

  components: {
    CommunityUser,
    CommunityButton,
    VueEditor,
    GradientBackLayout,
    BoxShadow,
    Separator,
    CommunityTextInput,
    CommunityUserAssignment,
    SwitchInput,
    CustomMailHistory,
    ScheduledMailManagement,
    Tabs
  },

  computed: {
    ...mapGetters([
      'origin',
      'communityInfo',
      'communityMembers',
      'theme',
      'communityMembersCount',
      'communityTags',
    ]),
    tooltipTitle () {
      return this.copied ? `${this.$t('text_copied')}` : `${this.$t('info_nickname', {code:"*|NICKNAME|*"})}`;
    }
  },
  watch: {
    switchTab() {
      this.selectedTab = this.switchTab ? 1 : 0;
    }
  },
  data() {
    return {
      copied:false,
      email: {
        subject: this.$t('custom_mail_subject_placeholder'),
        content: `${this.$t('custom_mail_hello_text')}<br><br>${this.$t('custom_mail_content')}<br><br>${this.$t('custom_mail_content2')}<br><br>`,
        userIds: [],
      },
      notification: {
        userIds: [],
        title: this.$t('custom_notification_subject_placeholder'),
        text: `${this.$t('custom_notification_hello_text')}\n\n${this.$t('custom_notification_content')}\n\n${this.$t('custom_notification_content2')}\n\n`,
      },
      isEditing: false,
      isNotificationEditing: false,
      members: {
        all: [],
        filtered: [],
        filteredAdded: [],
        added: []
      },
      tags: [],
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'header': 1 }, { 'header': 2 }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image', 'formula'],
        [{ 'direction': 'rtl' }],
        ['clean'],
      ],
      editorOptions: {},
      currentRequest: null,
      notificationSourceTab: [
        {
          id: 0,
          name: this.$t('custom_notification_label_email'),
        },
        {
          id: 1,
          name: this.$t('custom_notification_label_notif'),
        }
      ],
      selectedTab: 0,
      switchTab: false,
      pageTabs: [
        { id: 0, title: this.$t('notify') },
        { id: 1, title: this.$t('history') },
        { id: 2, title: this.$t('scheduled') },
      ],
      pageTabsActive: 0

    }
  },
  created() {
    this.editorOptions = {
      modules: {
        toolbar: {
          container: this.customToolbar,
          handlers: {
            image: this.imageHandler,
          },
        },
      },
    };
  },
  methods: {
    async handleTabsChange(e){
      console.log(" handleTabsChange e:", e)
      switch (e) {
        case 1:
          await this.$refs.CustomMailHistory.fetchEmails();
          break;
        case 2:
          await this.$refs.ScheduledEmailComponent.fetchScheduledEmails();
          break;
      }
      this.pageTabsActive = e;
    },

    handleCopy() {
      this.copyNicknameCode();
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  copyNicknameCode() {
    const textToCopy = "*|NICKNAME|*";

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          console.log("Texto copiado al portapapeles correctamente.");
          
        })
        .catch(err => {
          console.error("Error al copiar al portapapeles:", err);
        });
    } 
  },
    async fetchMembers(filter, array) {
      const request = store.dispatch(COMMUNITY_FETCH_MEMBERS, {
        notSetCommunityMembers: true,
        order: 'recent',
        nickname: filter?.nickname,
        filter: filter?.filter,
      });

      this.currentRequest = request;
      const data = await request;
      if (this.currentRequest !== request) return;

      if (filter) this.members[array] = data.communityMembers.map(m => m.identifier);
      else this.members.all = data.communityMembers;
    },
    async sendEmail() {
      this.email.userIds = this.members.added;

      const emailData = {
        subject: this.email.subject,
        content: this.email.content,
        userIds: this.email.userIds,
      };

      try {
        await store.dispatch(COMMUNITY_SEND_EMAIL, { data: emailData });
        Swal.fire({
          text: this.$t('custom_mail_sent'),
          icon: "success",
          customClass: { popup: "swal-customSuccess" },
        });
        this.$refs.userAssingment.deleteAllMembers();
      } catch (error) {
        console.error("Error sending email:", error);
        Swal.fire({
          text: `${this.$t('custom_mail_error')}: ${error.message || error}`,
          icon: "error",
          customClass: { popup: "swal-customError" },
        });
      }
    },
    async sendScheduledEmail(scheduledDate) {
      this.email.userIds = this.members.added;

      const emailData = {
        subject: this.email.subject,
        content: this.email.content,
        userIds: this.email.userIds,
        scheduledDate
      };
      try {
        await store.dispatch(COMMUNITY_SCHEDULE_SEND_EMAIL, { data: emailData });
        Swal.fire({
          text: this.$t('custom_mail_scheduled'),
          icon: "success",
          customClass: { popup: "swal-customSuccess" },
        });
        this.$refs.userAssingment.deleteAllMembers();
      } catch (error) {
        console.error("Error sending email:", error);
        Swal.fire({
          text: `${this.$t('custom_mail_error')}: ${error.message || error}`,
          icon: "error",
          customClass: { popup: "swal-customError" },
        });
      }
    },
    async sendNotification() {
      this.notification.userIds = this.members.added;
      this.notification.text = this.notification.text.split("<p><br></p>").join("<br>");
      try {
        await store.dispatch(COMMUNITY_SEND_NOTIFICATION, { data: this.notification });
        Swal.fire({
          text: this.$t('custom_notification_sent'),
          icon: "success",
          customClass: { popup: "swal-customSuccess" },
        });
        this.$refs.userAssingment.deleteAllMembers();
      } catch (error) {
        console.error("Error sending notification:", error);
        Swal.fire({
          text: `${this.$t('custom_notification_error')}: ${error.message || error}`,
          icon: "error",
          customClass: { popup: "swal-customError" },
        });
      }
    },
    controlPasteEvent(event) {
      event.preventDefault();
      const text = (event.clipboardData || window.clipboardData).getData('text/plain');
      document.execCommand('insertText', false, text);
    },
    imageHandler() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        const file = input.files[0];

        const maxSize = 1048576; // 1MB in bytes
        if (file.size > maxSize) {
          Swal.fire({
            text: this.$t('custom_mail_image_size_limit'),
            icon: "error",
            customClass: { popup: "swal-customError" },
          });
          return;
        }

        try {
          const response = await this.$store.dispatch('UPLOAD_IMG_TO_S3', {
            file: file,
            publicLogo: "true"
          });
          console.log("input.onchange=  response:", response)

          // Obtener la URL de la imagen de la respuesta del backend
          const imageUrl = response.path;

          const range = this.$refs.editor.quill.getSelection();
          if (range) {
            this.$refs.editor.quill.insertEmbed(range.index, 'image', imageUrl);
          } else {
            // Inserta la imagen al final si no hay un cursor activo
            this.$refs.editor.quill.insertEmbed(this.$refs.editor.quill.getLength() - 1, 'image', imageUrl);
          }
        } catch (error) {
          Swal.fire({
            text: `${this.$t('custom_mail_image_upload_error')}: ${error.message || error}`,
            icon: "error",
            customClass: { popup: "swal-customError" },
          });
        }
      };
    },
  },
  async mounted() {
    await store.dispatch(COMMUNITY_FETCH_TAGS)
    .then((data) => {
      if (data.publicTags === undefined && data.tags === undefined) this.tags = [];
      else if (data.publicTags === undefined) this.tags = data.tags;
      else if (data.tags === undefined) this.tags = data.publicTags;
      else this.tags = data.publicTags.concat(data.tags);
    });
    await this.fetchMembers(null, null);
    if (this.$route.params.addedMembers) this.members.added = this.$route.params.addedMembers;
  },
};
</script>
<style lang="scss" scoped>
.EmailCustomPageV2 {
  &--Container{
    position:relative;

  }
    &--Box {
      padding: 50px;
      flex-direction: column;
      border:none;
      @media only screen and (max-width: 600px) {
        padding: 10px;
      }
    }
  
      :deep &--Tabs{
        position: absolute;
          top: -32px;
          right: 0;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: bold;
          display: flex;
          padding:0;

        .Tab{
          background: #f6f6f6;
            cursor: pointer;
            display: flex;
            margin-right: 5px;
            padding: 7px 16px;
            font-size: 15px;
            letter-spacing: -0.01em;
          &--Active {
            background: rgb(255, 255, 255);
            font-weight: bold;
            color: var(--primary-color-text);
            
          }
        }
    }

    &--Switcher {
      width: 100%;
      margin: 0 auto 20px auto;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 10px;
    }
    &--Mail, &--Notification {
      display: grid;
      grid-template-columns: 25% calc(75% - 100px);
      grid-gap: 80px;
      @media only screen and (max-width: 600px) {
        display: block;
      }
    }
    &--MailInfo, &--NotificationInfo {
      h1 {
        font-size: 29px;
        letter-spacing: 0.01em;
        color: #3E3E3E;
      }
      p {
        margin: 20px 0;
        font-size: 18px;
        color: #747474;
      }
    }
    &--MailEditionContainer{
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
    &--MailEdition, &--NotificationEdition {
      .TextInput {
        width: 100%;
        padding: 0;
        input {
          font-size: 17px;
          letter-spacing: 0.01em;
          color: #3E3E3E;
          &:disabled {
            cursor: default !important;
          }
        }
      }
      textarea {
        width: 100%;
        min-height: 156px;
        background: #F5F5F5;
        border: 1px solid #D3D3D3;
        padding: 20px;
        box-sizing: border-box;
        resize: none;
        outline: none;
        font-size: 15.6px;
        letter-spacing: 0.01em;
        color: black;
        font-family: inherit;
        &:disabled {
          cursor: default !important;
        }
      }
    }
    &--MailPhoto {
      height: 51px;
      background: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 51px;
      }
    }
    &--MailContent, &--NotificationContent {
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      padding: 20px;
      max-width: 100%;
      min-height: 156px;
      overflow-wrap: break-word; 
      word-wrap: break-word; 
      white-space: pre-wrap;
    }
    &--MailContentEditor {
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      max-width: 100%;
    }
  }
  .info-icon:hover {
    cursor: pointer;

  }
</style>
