<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
import DOMPurify from 'dompurify'
import Spinner from '~/components/Spinner.vue'

const store = useStore()

const emailHistory = ref([])
const isLoading = ref(false)
const errorMsg = ref(null)

const totalPages = ref(1)
const currentPage = ref(1)

const filterOptions = [
    { value: 'date', type: 'date', icon: 'fa-calendar', label: 'Fecha' },
    { value: 'nickname', type: 'text', icon: 'fa-user', label: 'Nickname' },
    { value: 'id', type: 'text', icon: 'fa-id-card', label: 'ID' },
    { value: 'subject', type: 'text', icon: 'fa-file-alt', label: 'Asunto' }
]

const onlyOneVisible = ref(false)
const selectedFilter = ref(null)
const showFilterInput = ref(false)
const filterValue = ref('')

const isEmailsFiltered = ref(false)

const visiblePages = computed(() => {
    const pages = []
    const start = Math.max(1, currentPage.value - 2)
    const end = Math.min(totalPages.value, currentPage.value + 2)
    for (let i = start; i <= end; i++) {
        pages.push(i)
    }
    return pages
})

const fetchEmails = async (filter = null, value = null, page = 1) => {
    isLoading.value = true
    errorMsg.value = null
    try {
        isEmailsFiltered.value = !!(filter && value)
        const data = await store.dispatch("FETCH_COMMUNITY_CUSTOM_EMAIL_HISTORY", {
            filter, value, page, pageSize: 20
        })
        emailHistory.value = data.objects || []
        if (emailHistory.value.length === 0) {
            errorMsg.value = 'noEmailsFound'
        }
        totalPages.value = data.maxPages - 1 || 1
        currentPage.value = data.page || page
    } catch (err) {
        errorMsg.value = 'errorLoadingEmails'
        console.error(err)
    } finally {
        isLoading.value = false
    }
}

const selectFilterType = (option) => {
    if ((selectedFilter.value && selectedFilter.value.value === option.value) && showFilterInput.value) {
        selectedFilter.value = null
        showFilterInput.value = false
        filterValue.value = ''
        onlyOneVisible.value = false
        return
    }
    selectedFilter.value = option
    showFilterInput.value = true
    filterValue.value = ''
    onlyOneVisible.value = true
}

const applyFilter = () => {
    if (!selectedFilter.value || !filterValue.value) return
    fetchEmails(selectedFilter.value.value, filterValue.value, 1)
}

const changePage = (newPage) => {
    if (newPage < 1 || newPage > totalPages.value) return
    const filter = selectedFilter.value ? selectedFilter.value.value : null
    fetchEmails(filter, filterValue.value, newPage)
}

const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
}

const clearFilters = () => {
    selectedFilter.value = null
    showFilterInput.value = false
    filterValue.value = ''
    onlyOneVisible.value = false
}

defineExpose({
    fetchEmails
})
</script>

<template>
    <div class="MailHistoryPage">
        <div class="header">
            <h1>{{ $t('emailHistory') }}</h1>
            <Spinner class="spinner" v-if="isLoading" dark></Spinner>
        </div>
        <div class="filter-bar">
            <div class="filter-options">
                <i class="fas fa-times-circle" v-show="isEmailsFiltered"
                    @click="[fetchEmails(null, null), clearFilters()]"></i>
                <button v-for="(filterOption, idx) in filterOptions" :key="idx" class="filter-option"
                    :class="{ active: selectedFilter?.value === filterOption.value }"
                    v-show="!onlyOneVisible || selectedFilter?.value === filterOption.value"
                    :title="$t('filterBy') + ' ' + $t(filterOption.value)" @click="selectFilterType(filterOption)">
                    <i class="fas" :class="filterOption.icon"></i>
                </button>
            </div>
            <div v-if="showFilterInput" class="filter-input">
                <input :type="selectedFilter ? selectedFilter.type : 'text'" v-model="filterValue"
                    :placeholder="$t('filterBy') + ' ' + $t(selectedFilter.value)"
                    :title="$t('filterBy') + ' ' + $t(selectedFilter.value)" @keyup.enter="applyFilter" />
                <button :disabled="isLoading" @click="applyFilter">{{ $t('search') }}</button>
            </div>
        </div>
        <div class="mail-list">
            <p v-if="errorMsg">{{ $t(errorMsg) }}</p>
            <ul class="list" v-else>
                <li v-for="(mail, index) in emailHistory" :key="index" class="list-item">
                    <p class="mail-recipient">
                        <span>{{ $t('toWho') }}:</span> {{ mail.recipient }}
                    </p>
                    <p class="mail-date">
                        <span>{{ $t('date') }}:</span> {{ formatDate(mail.creationDate) }}
                    </p>
                    <p class="mail-content">
                        <span>{{ $t('subject') }}:</span> {{ mail.title }}
                    </p>
                    <p class="mail-content">
                        <span>{{ $t('content') }}:</span>
                        {{ DOMPurify.sanitize(mail.params.EL_CONTENT, { ALLOWED_TAGS: [] }) }}
                    </p>
                </li>
            </ul>
        </div>
        <div class="pagination" v-if="totalPages > 1">
            <button @click="changePage(1)" :disabled="currentPage === 1" :title="$t('first')">
                <i class="fas fa-angle-double-left"></i>
            </button>
            <button @click="changePage(currentPage - 1)" :disabled="currentPage <= 1" :title="$t('previous')">
                <i class="fas fa-angle-left"></i>
            </button>
            <button v-for="pageNumber in visiblePages" :key="pageNumber" @click="changePage(pageNumber)"
                :class="{ active: pageNumber === currentPage }" :title="$t('page', { pageNumber })">
                {{ pageNumber }}
            </button>
            <button @click="changePage(currentPage + 1)" :disabled="currentPage >= totalPages" :title="$t('next')">
                <i class="fas fa-angle-right"></i>
            </button>
            <button @click="changePage(totalPages)" :disabled="currentPage === totalPages" :title="$t('last')">
                <i class="fas fa-angle-double-right"></i>
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.MailHistoryPage {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 70dvh;
    padding: 20px;
    background: #F5F5F5;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.filter-bar {
    display: flex;
    gap: 0.5rem;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.filter-options {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.filter-option {
    background: #ddd;
    border: 1px solid #ccc;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-size: 0.875rem;
    transition: background 0.2s;
}

.filter-option:hover {
    background: #ccc;
}

.filter-option.active {
    background: #bbb;
    border-color: #999;
}

.filter-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.filter-input input {
    padding: 0.3rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.filter-input input:hover,
.filter-input input:focus {
    border: 1px solid #aaa;
}

.filter-input button {
    background: var(--primary-color);
    color: #fff;
    border: none;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s;
}


.mail-list {
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-item {
    background-color: #fff;
    border: 1px solid #e5e7eb;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    transition: background-color 0.2s ease-in-out;
}

.list-item:hover {
    background-color: #f3f4f6;
}

.mail-recipient {
    font-weight: bold;
}

.mail-date {
    font-size: 0.875rem;
    color: #6b7280;
    font-weight: bold;
}

.mail-content {
    margin-top: 0.25rem;
}

.mail-content span {
    font-weight: bold;
}

.pagination {
    padding: 20px 0.5rem 0.5rem 0.5rem;
    text-align: center;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    border-top: 1px solid #ccc;
}

.pagination button {
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #f0f0f0;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.pagination button:hover:not(:disabled) {
    background: #ddd;
    font-weight: bold;
}

.pagination button.active {
    background: #bbb;
    border-color: #999;
    font-weight: bold;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
